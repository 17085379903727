:root {
  --gradient-light: #487af9;
  --gradient-dark: #2c4b99;
  --bg-regular: #3458b2;
  --bg-dark: #2f4f9f;
  --bg-darker: #284387;
  --text: #f8f8f8;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, 0.25);
  --border-color: #3458b2;

  --highlight: #ffa231;
  --highlight-opaque: rgba(#ffa231, 0.8);
  --highlight-box-shadow: 0 0 0 0.5px #fff, 0 0 1.5px 3px #ffa231;
  --line-color: rgba(#f8f8f8, 0.5);

  --form-width: 600px;
  --overlay-color: rgba(0, 0, 0, 0.25);

  --space-xs: 0.5rem;
  --space-sm: 1rem;
  --space-md: 2rem;
  --space-lg: 4rem;
  --space-xl: 6rem;
  --space-page: 8rem;

  // borders
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 1rem;

  // CSS breakpoint values
  --screen-xs: 576px;
  --screen-sm: 768px;
  --screen-md: 992px;
  --screen-lg: 1200px;
  --screen-xl: 1440px;
}

[color-scheme="umid_uz"] {
  --gradient-light: #ffffff;
  --gradient-dark: #ffffff;
  --bg-regular: #a6e1d7;
  --bg-dark: white;
  --bg-darker: #15ac92;
  --text: #333333;
  --text-header: white;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, 0.25);
  --border-color: #a6e1d7;
  --select-border: #15ac92;
  // --gradient-light: white;
  // --gradient-dark: white;
  // --bg-regular: white;
  // --bg-dark: #34bda9;
  // --bg-darker: #9d1c20;
  // --text: #f3f3f3;
  // --text-header: white;
  // --grey: #f3f3f3;
  // --grey-opaque: rgba(#f3f3f3, 0.25);

  --highlight: #15ac92;
  --highlight-opaque: rgba(#15ac92, 0.8);
  --highlight-box-shadow: 0 0 0 0.5px #fff, 0 0 1.5px 3px #15ac92;
  --line-color: rgba(#333333, 0.5);
}

[color-scheme="umid_az"] {
  --gradient-light: #ffffff;
  --gradient-dark: #ffffff;
  --bg-regular: #70000a40;
  --bg-dark: white;
  --bg-darker: #70000a;
  --text: #333333;
  --text-header: white;
  --border-color: #70000a;
  --select-border: #70000a;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, 0.25);
  --highlight: #70000a;
  --highlight-opaque: rgba(#70000a, 0.8);
  --highlight-box-shadow: 0 0 0 0.5px #fff, 0 0 1.5px 3px #70000a;
  --line-color: rgba(#333333, 0.5);
}

[color-scheme="oh"] {
  --gradient-light: transparent;
  --gradient-dark: transparent;
  --bg-regular: #FFF;
  --bg-dark: #f8f8f8;
  --bg-darker: #203038;
  --text: #000;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, 0.25);

  --highlight: #71EAC6;
  --highlight-opaque: rgba(#246b8f, 0.8);
  --box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --highlight-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 8px 0px rgba(113, 234, 198, 0.5);
  --line-color: rgba(#f8f8f8, 0.5);
  --border-color: #4c9c84;
}

[color-scheme="gmo"] {
  --bg-regular: white;
  --bg-dark: white;
  --bg-darker: #274387;
  --text: #333333;
  --grey: #f3f3f3;
  --grey-opaque: rgba(#f3f3f3, 0.25);
  --border-color: white;
  --highlight: #dd6b1c;
  --line-color: rgba(#f8f8f8, 0.5);
}

@media only screen and (max-width: 768px) {
  :root {
    --form-width: 90%;
  }
}
#root.oh {
  --form-width: 90%;
}
.oh .main {
  height: auto;
  width: 100%;
}
