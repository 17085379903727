* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  color: var(--text);
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  color: var(--text);
  overflow: hidden;
}

.main {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  color: var(--text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: linear-gradient(
    to right,
    var(--gradient-light),
    var(--gradient-dark)
  );
  overflow: auto;

  .logo {
    position: absolute;
    top: 35px;
    left: 35px;
    width: 240px;
    height: 60px;
  }

  .language {
    position: absolute;
    bottom: 15px;
    left: 15px;

    color: var(--text);

    font-size: 12px;

    svg {
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    height: auto;
    overflow-y: scroll;
    justify-content: flex-start;

    .logo {
      top: 0;
      left: 0;
      margin: 14px 0;
      position: relative;
      width: 120px;
      height: 30px;
    }
  }
}
