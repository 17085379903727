.form-item {
  .form-input {
    width: 100%;
    box-sizing: border-box;
    height: 2rem;
    background: var(--bg-regular);
    border: none;
    font-size: 1rem;
    color: var(--text);
    transition: .2s;
    border-radius: 5px;
    padding-left: 35px;
    padding-right: 10px;
    transition: .2s;

    select {
      background-color: var(--bg-dark);
    } 

    &:focus {
      outline: none;
    }

    &.form-input--phone {
      padding: 0;
      overflow: hidden;

      .PhoneInputCountry {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }

      .PhoneInputInput {
        background: var(--bg-regular);
        border: none;
        font-size: 14px;
        width: 100%;
        height: 100%;
        color: var(--text);
        padding: 0px 10px 0px 50px;
    
        &:focus {
          outline: none;
        }
      }

      .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border, .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow{
        box-shadow: none;
        color: var(--text);
      }
    
      .PhoneInputCountryIcon--border {
        outline: none;
        border: none;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  
  input:-webkit-autofill + svg path{
    fill: var(--bg-dark);
  }

  .icon {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: var(--line-color);
    transition: .2s;
  }

  .error-message {
    position: absolute;
    top: 100%;
    left: 0px;
    font-size: 12px;
    color: red;
  }

}

@media only screen and (max-width: 768px) {
  .form-item .form-input {
    font-size: 16px;
  }
}