.form-item {
  width: 100%;
  position: relative;

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:focus-within, &.valid {
    .form-item--title, .icon {
      color: var(--text);
    }
  }

  .form-item--title {
    margin: 0;
    font-size: 12px;
    color: var(--line-color);
    transition: .2s;
  }
}

@media only screen and (max-width: 768px) {
  .form-item.inline .form-item--title {
    flex: 1;
  } 
}