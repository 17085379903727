.button {
  background: var(--highlight);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 35px;
  min-width: 120px;
  color: var(--text);
  font-size: 20px;
  cursor: pointer;
  transition: .2s;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .5;

    &:hover {
      background: var(--highlight);
    }
  }
}