.oh-form {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 60px 48px;
  box-shadow: none;
  border-radius: 32px;

  .form-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    border-left: 8px solid var(--highlight);

    margin-top: 0px;
    margin-bottom: 24px;
    min-height: 130px;
    background-color: var(--bg-dark);
    border-radius: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media only screen and (max-width: 480px) {
      min-height: 100px;
      padding-left: 8px;
      padding-right: 0;
    }

    .form-header--title {
      max-width: 400px;
      // font-size: clamp(1.5rem, 1.308rem + 0.769vw, 2rem);
      font-size: clamp(1.125rem, 0rem + 5vw, 2rem);
      word-break: keep-all;
      margin-bottom: 8px;
      text-align: left;
      letter-spacing: 1.28px;

      @media only screen and (min-width: 360px) and (max-width: 480px) {
        font-size: 27px;
        // word-break: normal;
      }

      @media only screen and (max-width: 360px) {
        font-size: 18px;
        word-break: normal;
      }
    }
  }

  .form-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-areas:
      "interested-in interested-in"
      "wants-to-connect wants-to-connect"
      "subtitle subtitle"
      "name name"
      "toggled toggled"
      "age-range age-range"
      "phone phone"
      // "email email"
      "rednote-id rednote-id"
      // "best-contact best-contact"
      "submit submit";

    .toggled-fields {
      grid-area: toggled;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-subtitle {
      grid-area: subtitle;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      margin: 12px 0;
    }

    .submit {
      grid-area: submit;
    }

    .form-group {
      background: #e3e3e3;
      margin-top: 1rem;
      padding: 1.4rem 1rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .form-grid-not-connect {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-areas:
      "interested-in interested-in"
      "wants-to-connect wants-to-connect"
      "submit submit";

    .form-subtitle {
      display: none;
    }

    .toggled-fields {
      display: none;
    }

    .submit {
      grid-area: submit;
    }
  }

  .form-item {
    .form-item--title {
      font-size: 18px;
      margin-bottom: 8px;
    }

    .form-input {
      height: 36px;
      border-radius: 10px;
    }

    .form-select--items {
      flex-direction: row;

      border: none;
      .item {
        height: 48px;
        background-color: var(--bg-regular);
        font-size: 18px;
        border: 4px solid var(--bg-dark);
        border-radius: 10px;

        &:hover {
          border-color: var(--border-color) !important;
        }
      }

      input[type="radio"] {
        font-size: 18px;
      }
    }
  }

  .form-item.inline {
    padding: 12px 0;
    .form-item--title {
      margin-bottom: 0;
    }
    .form-checkbox {
      input[type="checkbox"] {
        width: 25px;
        height: 25px;
      }
      .checkbox__control {
        background-color: white;
        box-shadow: var(--box-shadow);
        width: 25px;
        height: 25px;
      }
    }

    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;

    // .form-item--title {
    //   flex-grow: 1;
    // }

    // .form-checkbox {
    //   flex-grow: 0;
    // }
  }

  @media screen and (max-width: 768px) {
    padding: 42px 10px;

    .form-item.inline {
      display: flex;
      flex-direction: row-reverse;
      .form-item--title {
        flex-grow: 1;
      }
      .form-checkbox {
        flex-grow: 0;
      }
    }
  }

  .form-item .form-select--items .item.active {
    border-color: var(--highlight);

    &:hover {
      border-color: var(--border-color) !important;
    }
  }

  input,
  .PhoneInput,
  .item {
    border-radius: 10px;
    background: var(--bg-regular);
    box-shadow: var(--box-shadow);

    &:focus {
      box-shadow: var(--highlight-box-shadow);
    }
  }

  button {
    width: 100%;

    color: var(--bg-regular);
    border: none;
    border-radius: 100px;
    padding: 15px 60px;
    margin-top: 20px;
    background-color: #6bddbb;
    box-shadow: 0px 0px 8px 0px var(--highlight);
    opacity: 1;

    &:hover {
      background-color: #4c9c84;
    }

    &.disabled {
      background-color: #abe8d6;
      opacity: 0.7;
    }
  }
}
