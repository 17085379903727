.gmo-form--background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(black, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

.gmo-form {
  box-shadow: 0px 5px 10px rgba(black, 0.15);
  width: 600px;

  .form-grid {
    column-gap: 15px;
    row-gap: 25px;
    grid-template-areas:
      "phone empty"
      "best-contact best-contact"
      "toggled toggled"
      "submit submit";

    .form-item {
      .form-input,
      .form-select--items {
        border: 1px solid var(--text);
      }

      .form-select--items {
        overflow: hidden;

        .item {
          color: var(--text);
          border: none;
          border-right: 1px solid var(--text);
          border-radius: 0;

          &:last-child {
            border-right-color: white;
          }

          &:hover,
          &.active {
            border-color: var(--text);
            background-color: var(--text);
            color: white;
            border-radius: 0;

            svg path {
              transition: 0.2s;
              fill: white;
            }
          }
        }
      }
    }

    .form-grid--toggled-fields {
      grid-area: toggled;
      grid-template-columns: 1fr 0.5fr 0.5fr;
      grid-template-areas:
        "gender gender gender"
        "city state postal";

      .form-item {
        .form-input {
          padding-left: 10px;
          border-radius: 0;
          font-size: 14px;
        }

        &:nth-child(2) .form-input {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-left: 35px;
        }

        &:last-child .form-input {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    .form-grid--submit .button {
      color: white;
    }
  }

  &.gmo-form--mobile {
    width: 90%;

    .form-grid {
      grid-template-areas:
        "phone phone"
        "best-contact best-contact"
        "toggled toggled"
        "submit submit";

      .form-item:nth-child(2) .item {
        min-width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid var(--text);
        }

        &:nth-child(2) {
          border-right: none;
        }
      }
    }
  }
}
