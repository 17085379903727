.spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 10px solid var(--grey);
  border-top: 10px solid var(--highlight);
  box-shadow: 0px 0px 15px rgba(black, .5);
  animation: spin 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}