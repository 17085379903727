.in-ul-form {
  .form-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 25px;

    grid-template-areas:
      "name name"
      "phone phone"
      "best-contact best-contact"
      "accepted-christ accepted-christ"
      "wants-to-connect has-questions"
      "toggled toggled"
      "submit submit";

    .form-grid--toggled-fields {
      grid-template-columns: 1fr 0.5fr 0.5fr;
      column-gap: 2px;
      grid-template-areas:
        "gender gender gender"
        "city state postal";

      .form-item {
        .form-input {
          border-radius: 0;
          padding-left: 10px;
        }

        &:nth-child(2) .form-input {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-left: 35px;
        }

        &:last-child .form-input {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
