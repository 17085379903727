.telegram-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);

  display: flex;
  align-items: center;
  justify-content: center;

  .login-wrapper {
    padding: 24px;
    background-color: var(--text);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    animation: .3s ease 0s 1 entrance;

    h4 {
      width: 100%;
      text-align: center;
      padding: 0px 24px 5px;
      letter-spacing: .5px;
      font-size: 22px;
      border-bottom: 2px solid var(--bg-dark);
      color: black;
    }

    p {
      font-size: 12px;
      text-align: center;
      margin: 12px;
      color: black;
    }

    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 25px;
      height: 25px;
      transition: .2s;
      cursor: pointer;
      
      path {
        color: var(--bg-regular);
      }

      &:hover {
        color: var(--bg-dark);
      }
    }
  }
}

@keyframes entrance {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}