.page-form {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  color: var(--text);
  position: relative;
  background-image: linear-gradient(
    to right,
    var(--gradient-light),
    var(--gradient-dark)
  );
  padding: var(--space-sm) var(--space-md);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--gradient-dark);
  }

  &::-webkit-scrollbar-thumb {
    background: #00000050;
    border-radius: 5px;
  }

  .language {
    color: var(--text);
    font-size: 12px;
    position: fixed;
    bottom: var(--space-sm);

    svg {
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: 520px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 992px) {
  .page-form {
    row-gap: var(--space-md);
    .logo,
    .language {
      justify-self: center;
    }
  }
}
