.notification-list {
  position: fixed;
  top: var(--space-sm);
  left: 50%;
  transform: translateX(-50%);
  min-width: 350px;
  max-width: 60%;

  display: flex;
  flex-direction: column;

  .notification {
    color: var(--text-dark);
    width: 100%;
    background-color: white;
    border-radius: var(--border-radius-sm);
    border-top: 4px solid var(--secondary-default);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    margin-top: var(--space-sm);
    overflow: hidden;

    .text-container {
      padding: var(--space-sm);
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      * {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: var(--space-xs);
        color: var(--bg-darker);

        &:last-child {
          margin-right: 0;
          font-size: 1rem;
        }
      }

      :first-child {
        flex: 1;
      }

      .action {
        cursor: pointer;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .duration {
      grid-area: duration;
      height: 4px;

      .remaining {
        width: 50%;
        height: 100%;
        background-color: var(--bg-darker);
      }
    }
  }
}
