.form {
  background: var(--bg-dark);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--form-width);
  box-shadow: 0px 5px 10px rgba(black, 0.15);
  position: relative;
  justify-self: center;

  .form-header {
    width: 100%;
    box-sizing: content-box;
    text-align: center;
    background: var(--bg-darker);
    padding: 24px;
    margin-top: -24px;
    margin-left: 0px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;

    .form-header--back {
      position: absolute;
      top: var(--space-sm);
      left: var(--space-md);
      cursor: pointer;
      padding: var(--space-sm);
    }

    .form-header--title {
      font-size: 24px;
      margin: 0;
      letter-spacing: 1px;
      color: var(--text-header);
    }
  }

  .form-subtitle {
    width: 90%;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 24px;
    font-weight: normal;
  }

  .form-grid {
    width: 100%;
    display: grid;

    .form-grid--toggled-fields {
      grid-area: toggled;
      display: grid;
      row-gap: 25px;
    }

    .form-grid--submit {
      grid-area: submit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .age-warning {
    margin-top: var(--space-sm);
    font-size: 0.75rem;
  }

  .button-container {
    display: flex;
    align-items: center;
  }

  .submitting-spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--overlay-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submission-success-message {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    h2 {
      margin: 12px 0;
      padding: 12px 0;
      width: 80%;
      text-align: center;
      border-bottom: 2px solid var(--highlight);
    }

    p, a {
      text-align: center;
      margin: 12px 0;
    }
  }

  .form--hidden {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .form {
    margin-bottom: 24px;
    border-radius: 15px;

    .form-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .form-header--title {
        font-size: 18px;
      }
    }

    .form-subtitle {
      font-size: 12px;
    }

    .form-grid {
      grid-template-areas:
        "name name"
        "email email"
        "phone phone"
        "gender gender"
        "accepted-christ accepted-christ"
        "best-contact best-contact"
        "wants-to-connect wants-to-connect"
        "has-questions has-questions"
        "toggled toggled"
        "submit submit";

      .form-item:nth-child(5) .item {
        min-width: 50%;
       
      }

      .form-grid--toggled-fields {
        grid-template-columns: 1fr;
        row-gap: 12px;
        grid-template-areas:
          "city"
          "state"
          "postal"
          "gender";

        .form-item .form-input {
          border-radius: 5px;
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .form-header{
      display: flex;
      flex-direction: row;
      .form-header--back{
        position:unset;
        padding-left:5px;
      }
    }
    .form-grid{
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-template-areas:
      "name" 
      "email"
      "phone"
      "accepted-christ"
      "best-contact"
      "wants-to-connect"
      "has-questions"
      "toggled"
      "submit";
      .form-grid--toggled-fields {
          grid-template-columns: 1fr;
          column-gap: 2px;
          grid-template-rows: auto;
          grid-template-areas:
            "gender"
            "city"
            "state"
            "postal";
        }
    }
    .sf-form-grid {
      grid-template-areas:
        "toggled toggled"
        "submit submit";
        
    }

    
  }
}
