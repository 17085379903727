.in-connect-form {
  .form-header {
    display: flex;
    flex-direction: row;
    .form-header--back {
      position: unset;
    }
    .form-header--title {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 25px;

    grid-template-areas:
      "name name"
      "email phone"
      "accepted-christ accepted-christ"
      "best-contact best-contact"
      "wants-to-connect has-questions"
      "toggled toggled"
      "submit submit";

    .form-grid--toggled-fields {
      grid-template-columns: 1fr 0.5fr 0.5fr;
      column-gap: 2px;
      grid-template-areas:
        "gender gender gender"
        "city state postal";

      .form-item {
        .form-input {
          border-radius: 0;
          padding-left: 10px;
        }

        &:nth-child(2) .form-input {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-left: 35px;
        }

        &:last-child .form-input {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  .sf-form-grid {
    grid-template-areas:
      "toggled toggled"
      "submit submit";
  }
  @media only screen and (max-width: 520px) {
    .form-header {
      display: flex;
      flex-direction: row;
      .form-header--back {
        position: unset;
        padding-left: 5px;
      }
    }

    .form-grid {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-template-areas:
        "name"
        "email"
        "phone"
        "accepted-christ"
        "best-contact"
        "wants-to-connect"
        "has-questions"
        "toggled"
        "submit";
    }
    .sf-form-grid {
      grid-template-areas:
        "toggled toggled"
        "submit submit";
      .form-grid--toggled-fields {
        grid-template-columns: 1fr;
        column-gap: 2px;
        grid-template-rows: auto;
        grid-template-areas:
          "gender"
          "city"
          "state"
          "postal";
      }
    }
  }
}
