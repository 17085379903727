.telegram-form {
  background: var(--bg-dark);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--form-width);
  box-shadow: 0px 5px 10px rgba(black, 0.15);
  position: relative;

  .form-header {
    width: 100%;
    box-sizing: content-box;
    text-align: center;
    background: var(--bg-darker);
    padding: 24px;
    margin-top: -24px;
    margin-left: 0px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    h4 {
      font-size: 24px;
      margin: 0;
      letter-spacing: 1px;
    }
  }

  .form-item {
    margin-bottom: 24px;
  }

  .button {
    margin-top: 24px;
  }

  .submitting-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--overlay-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    color: red;
    margin-top: 24px;
  }
}
