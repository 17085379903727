.az-uz-form {
  .form-grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 25px;

    grid-template-areas:
      "name name"
      "phone phone"
      "best-contact best-contact"
      "toggled toggled"
      "submit submit";

    .form-grid--toggled-fields {
      grid-template-columns: 1fr 0.5fr 0.5fr;
      grid-template-areas: "gender gender gender";
    }

    .form-item {
      .form-input,
      .form-input.form-input--phone .PhoneInputInput {
        background-color: var(--bg-dark);
      }

      .form-input,
      .form-select--items {
        border: 1px solid var(--border-color);
      }

      .form-select--items {
        overflow: hidden;

        .active {
          border-color: var(--select-border);
        }
      }
    }

    .form-grid--submit .button {
      color: var(--text-header);
    }
  }

}
.aloqa .telegram-login .login-wrapper,
.elaqe .telegram-login .login-wrapper {
   background-color: white;
}
