$checkbox-size: 20px;

.form-item {
  .form-checkbox {
    margin: 0px 15px;
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    .checkbox__control {
      display: inline-grid;
      width: $checkbox-size;
      height: $checkbox-size;
      border-radius: 5px;
      border: 1px solid var(--text);
      padding: 2.5px;
      transition: .2s;
    
      svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
        pointer-events: none;
      }
    }

    input {
      opacity: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      cursor: pointer;

      &:hover + .checkbox__control {
        box-shadow: var(--highlight-box-shadow);
      }

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }
    }
  }
}