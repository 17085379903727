.form-item {
  width: 100%;

  .form-select--items {
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    color: var(--text);
    transition: 0.2s;
    border-radius: 5px;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2.5px;
    border: 2px solid var(--border-color);

    .item {
      flex: 1;
      text-align: center;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s;
      min-width: 25%;
      padding: 12px;
      border: 2px solid var(--bg-dark);
      border-right-color: var(--border-color);

      .select-icon {
        margin-bottom: 6px;
      }
      &:hover,
      &.active,
      &:focus {
        outline: none;
        background: var(--bg-regular);
        border-color: var(--border-color);
      }

      &.active {
        border-color: var(--text);
        border-radius: 5px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .form-item .form-select--items {
    flex-direction: row;
    border:none;
    .item {
      padding: 12px 0;
    }
  }
}

@media only screen and (max-width: 520px) {
  .form-item .form-select--items {
    flex-direction: column;
    .item{
      border: 2px solid var(--border-color);
      border-radius: 5px;
    margin: 1px;

    }}
}
